.driver-active .driver-overlay,
.driver-active * {
  pointer-events: none;
}

.driver-active .driver-active-element,
.driver-active .driver-active-element *,
.driver-popover,
.driver-popover * {
  pointer-events: auto;
}

@keyframes animate-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.driver-fade .driver-overlay {
  animation: animate-fade-in 0.2s ease-in-out;
}

.driver-fade .driver-popover {
  animation: animate-fade-in 0.2s;
}

.driver-popover {
  all: unset;
  box-sizing: border-box;
  color: #fff;
  margin: 0;
  padding: 15px;
  border-radius: 30px;
  min-width: 250px;
  max-width: 300px;
  box-shadow: 0 1px 10px #0006;
  z-index: 1000000000;
  position: fixed;
  top: 0;
  right: 0;
  backdrop-filter: brightness(240%) blur(18px);
  padding:30px;
  
}

.driver-popover * {
  font-family: Helvetica Neue, Inter, ui-sans-serif, 'Apple Color Emoji',
    Helvetica, Arial, sans-serif;
}

.driver-popover-title {
  font: 19px / normal sans-serif;
  font-weight: 700;
  display: block;
  position: relative;
  line-height: 1.5;
  zoom: 1;
  margin: 0;
}

.driver-popover-close-btn {
  all: unset;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 28px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  z-index: 1;
  text-align: center;
  transition: color;
  transition-duration: 0.2s;
}

.driver-popover-close-btn:hover,
.driver-popover-close-btn:focus {
  color: #fff;
}

.driver-popover-title[style*='block']+.driver-popover-description {
  margin-top: 5px;
}

.driver-popover-description {
  margin-bottom: 0;
  font: 14px / normal sans-serif;
  line-height: 1.5;
  font-weight: 400;
  zoom: 1;
}

.driver-popover-footer {
  margin-top: 15px;
  text-align: right;
  zoom: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.driver-popover-progress-text {
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
  zoom: 1;
}

.driver-popover-footer button {
  all: unset;
  display: inline-block;
  box-sizing: border-box;
  padding: 6px 8px;
  text-decoration: none;
  background-color: #ffffff20;
  color: #fff;
  font: 14px / normal sans-serif;
  cursor: pointer;
  outline: 0;
  zoom: 1;
  line-height: 1.3;
  border: 0px solid #ccc;
  border-radius: 6px;
}

.driver-popover-footer .driver-popover-btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.driver-no-interaction,
.driver-no-interaction * {
  pointer-events: none !important;
}

.driver-popover-footer button:hover,
.driver-popover-footer button:focus {
  background-color: #ffffff20;
}

.driver-popover-navigation-btns {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.driver-popover-navigation-btns button+button {
  margin-left: 4px;
}

.driver-popover-arrow {
  content: '';
  position: absolute;
  border: 5px solid #ffffff20;
  backdrop-filter: brightness(240%) blur(18px);
}

.driver-popover-arrow-side-over {
  display: none;
}

.driver-popover-arrow-side-left {
  left: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}

.driver-popover-arrow-side-right {
  right: 100%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}

.driver-popover-arrow-side-top {
  top: calc(100% + 1px);
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.driver-popover-arrow-side-bottom {
  bottom: 100%;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
}

.driver-popover-arrow-side-center {
  display: none;
}

.driver-popover-arrow-side-left.driver-popover-arrow-align-start,
.driver-popover-arrow-side-right.driver-popover-arrow-align-start {
  top: 15px;
}

.driver-popover-arrow-side-top.driver-popover-arrow-align-start,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-start {
  left: 15px;
}

.driver-popover-arrow-align-end.driver-popover-arrow-side-left,
.driver-popover-arrow-align-end.driver-popover-arrow-side-right {
  bottom: 15px;
}

.driver-popover-arrow-side-top.driver-popover-arrow-align-end,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-end {
  right: 15px;
}

.driver-popover-arrow-side-left.driver-popover-arrow-align-center,
.driver-popover-arrow-side-right.driver-popover-arrow-align-center {
  top: 50%;
  margin-top: -5px;
}

.driver-popover-arrow-side-top.driver-popover-arrow-align-center,
.driver-popover-arrow-side-bottom.driver-popover-arrow-align-center {
  left: 50%;
  margin-left: -5px;
}

.driver-popover-arrow-none {
  display: none;
}
.driver-active-element {
  border-radius: 24px;
}

